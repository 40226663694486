module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Whidbey Island Roadside Farm Stands","short_name":"Farm Stands","start_url":"/","background_color":"#8C3922","theme_color":"#8C3922","display":"minimal-ui","icon":"src/images/farmstand-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"owner","store":true,"attributes":{"boost":10}},{"name":"description","attributes":{"boost":5}},{"name":"wordpress_id","store":true},{"name":"city","store":true}],"resolvers":{"wordpress__wp_farmstands":{}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-164320360-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
